
import { computed, ref } from 'vue'
import { Product, buildProduct, CourseSeries } from 'momai'
import { add, queryCourse, getProductInfo } from '@/api/sales'
import { useRoute } from 'vue-router'
import { Toast } from 'vant'
import MomaiCoverUploaderSpecial from '@/components/MomaiCoverUploaderSpecial.vue'

export default {
  components: { MomaiCoverUploaderSpecial },
  setup: () => {
    const fee = ref<any>(0)
    const newProduct = ref<Product>(buildProduct())
    const { productId } = useRoute().params
    if (productId != null) {
      const id = productId + ''
      getProductInfo(id).then(e => {
        newProduct.value = e
        fee.value = (e.fee / 100).toFixed(2)
      })
    }
    const canNotSave = computed(() => {
      const v = newProduct.value
      const flag: boolean = v.title === '' || v.description === '' || v.expireTime === ''
      return flag || (v.type !== 2 && v.courseId === '')
    })
    const productOptions = [
      { text: '课程促销', value: 0 },
      { text: '线上活动', value: 1 },
      { text: '线下活动', value: 2 }
    ]
    const courseList = ref<CourseSeries[]>([])
    const fileList = ref([])
    const show = ref(false)
    const formatDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const onConfirm = (inDate: Date) => {
      show.value = false
      newProduct.value.expireTime = formatDate(inDate)
    }
    queryCourse('').then((r) => {
      courseList.value = r
    })
    const cond = ref('')
    const searchFn = () => {
      queryCourse(cond.value).then((r) => {
        courseList.value = r
      })
    }
    const formatter = (v: number) => (v / 100).toFixed(2)
    return {
      newProduct,
      fileList,
      show,
      onConfirm,
      productOptions,
      courseList,
      searchFn,
      cond,
      fee,
      formatter,
      canNotSave,
      saveCourseSeries: () => {
        newProduct.value.fee = fee.value * 100
        add(newProduct.value).then(() => {
          // newProduct.value = buildProduct()
          // fileList.value = []
          Toast.success('保存成功')
        })
      }
    }
  }
}
